// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import Homepage from './pages/Homepage';
import Register from './pages/Register';
import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard';
import TeacherExams from './pages/TeacherExams';
import StudentExams from './pages/StudentExams';
import ProtectedRoute from './components/ProtectedRoute';
import { UserProvider, useUser } from './contexts/UserContext';

const AppContent = () => {
    const { user, logout } = useUser();
    return (
        <Router>
            <Container>
                <div className="banner">
	                <Nav.Link as={Link} to="/"><img src="https://review.mtu.edu.vn/banner.jpg" alt="mtu" /></Nav.Link>
                </div>
                <Navbar className="navbar-custom" expand="lg">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="w-100">
                            {user.role === "student" && <Nav.Link as={Link} to="/student-exams">Tác giả</Nav.Link>}
                            {user.role === "reviewer" && <Nav.Link as={Link} to="/teacher-exams">Phản Biện</Nav.Link>}
                            {user.role === "admin" && <Nav.Link as={Link} to="/admin-dashboard">Admin</Nav.Link>}
                            {user.token ? (
                                <>
                                  <Nav.Link className="ms-auto">Xin chào : {user.userName }</Nav.Link>
                                  <Nav.Link onClick={logout}>Đăng xuất</Nav.Link>
                                </>
                            ) : (
                                <>
                                    <Nav.Link className="ms-auto" as={Link} to="/register">Đăng Ký</Nav.Link>
                                    <Nav.Link as={Link} to="/login">Đăng nhập</Nav.Link>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="page-content">
                    <Routes>
                        <Route path="/" element={<Homepage />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                        <Route element={<ProtectedRoute role="student" />}>
                            <Route path="/student-exams" element={<StudentExams />} />
                        </Route>
                        <Route element={<ProtectedRoute role="admin" />}>
                            <Route path="/admin-dashboard" element={<AdminDashboard />} />
                        </Route>
                        <Route element={<ProtectedRoute role="teacher" />}>
                            <Route path="/teacher-exams" element={<TeacherExams />} />
                        </Route>
                    </Routes>
                </div>
                <footer className="footer mt-4" role="contentinfo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    Tạp chí Khoa học và Công nghệ Trường Đại học Xây dựng Miền Tây
                                    <br />
                                    Giấy phép xuất bản số: 159/GP-BTTTT, ngày 29/03/2022 của Bộ TTTT
                                    <br />
                                    ISSN: 3030-4806
                                    <br />
                                    Địa chỉ: 20B Phó Cơ Điều, phường 3, Tp. Vĩnh Long, tỉnh Vĩnh Long
                                    <br />
                                    Email: editor@mtu.edu.vn
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Container>
        </Router>
    );
};

const App = () => (
    <UserProvider>
        <AppContent />
    </UserProvider>
);

export default App;
